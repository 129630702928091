.alpha-chevron {
  width: 0.75rem;
  height: 0.75rem;
  transition: transform 200ms ease-in;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.alpha-chevron-down {
    margin-top: 0rem;
  }
  &.alpha-chevron-up {
    margin-top: 0.25rem;
    transform: rotate(180deg);
  }
  &.alpha-chevron-left {
    transform: rotate(90deg);
  }
  &.alpha-chevron-right {
    transform: rotate(-90deg);
  }
  &.white {
    fill: #fff;
  }
}
