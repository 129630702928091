/********************************** Colors Starts here ********************************/

/* Primary */
$color-primary: #de1782;
$color-primary-light: #fbe5f1;

/* Secondary */
$color-secondary-dark: #004a97;
$color-secondary: #2696e7;
$color-secondary-light: #bbdef7;
$color-secondary-lighter: #e3f2fb;
$color-secondary-unselected: #6897c4;

/* Red & Amber */
$color-highlight-red: #e41e54;
$color-highlight-orange: #ffa000;
$color-highlight-error: #fbd9d9;
$color-highlight-red-orange: #e95258;
$color-highlight-amber: #f2994a;
$color-highlight-yellow: #fdc04d;

// Green
$color-green: #00b45a;

/* Greys */
$color-grey: #979797;
$color-grey-light: #c4c4c4;
$color-grey-lighter: #f3f3f3;
$color-grey-border: #e0dede;
$color-grey-font-unselected: #586e84;
$color-grey-font-selected: #4f4f4f;
$color-warm-grey: #999999;
$color-white-grey: #ececec;

/* Black & White */
$color-black: #000;
$color-white: #ffffff;

/* Black & White */
$color-blue-lighter: #f2faff;
/********************************** Colors Ends here ********************************/
