$width-default: 248px;
$width-small: 144px;
$width-medium: 180px;
$width-large: 250px;

.alpha-tooltip-container {
  span.white-space-wrap {
    white-space: normal !important;
  }
  border-radius: 6px;
  box-shadow: 0 0 16px 0 rgba(51, 51, 51, 0.2);
  background-color: #ffffff;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  width: $width-default;
  text-align: center;

  &.tooltip--small {
    width: $width-small;
  }
  &.tooltip--medium {
    width: $width-medium;
  }
  &.tooltip--large {
    width: $width-large;
  }

  header {
    height: 28px;
    background-color: #999999;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
  }

  .headerText {
    font-size: 12px;
    color: #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .tooltipCard {
    &:first-child {
      padding-top: 1rem;
    }
    .tooltipContent {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      white-space: pre-wrap;
      .tooltipIndicatorContainer {
        display: inline-block;
        .tooltipIndicator {
          height: 8px;
          width: 16px;
          min-height: 8px;
          min-width: 16px;
          display: inline-block;
          margin-right: 4px;
          align-items: center;

          &.typeSquare {
            border-radius: 0;
          }
        }
      }
      .tooltipDescriptions {
        text-align: left;
        white-space: normal;
      }
    }
    .tooltipContentGroup {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .tooltipContent {
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        align-items: baseline;
        white-space: pre-wrap;
        .tooltipLabel {
          display: flex;
          .tooltipIndicatorContainer {
            display: block;
          }
        }
      }
    }
  }

  .contentSection:not(:last-child) {
    border-bottom: 1px solid #ececec;
  }

  .tooltipSplit {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .tooltipDefaultValue {
    font-size: 16px;
    color: #999999;
  }

  .tooltipDefaultLabel {
    font-size: 16px;
    color: #000000;
  }
  &.mobile {
    box-shadow: none;
    text-align: left;
    min-width: 100%;
    header {
      display: none;
    }
    .tooltipIndicatorContainer {
      margin-left: -12px;
    }
    .contentSection {
      display: flex;
      flex-wrap: wrap;
      &:not(:first-child) {
        padding-top: 16px;
      }
      > .tooltipCard {
        width: 50%;
        &:first-child {
          padding-top: 0;
        }
      }
      &.hasDescriptions {
        > .tooltipCard {
          width: 100%;
        }
      }
    }
  }
}
