html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-family: 'IBM Plex Sans';
  vertical-align: baseline;
}

input {
  font: inherit;
  font-size: 14px;
  color: inherit;
  background-color: var(--inputPrimaryBackground);
  padding: 9.5px 14px;
  line-height: 1.21428571em;
}

input[type='text'] {
  border-radius: 5px;
}

input[type='number'] {
  background-color: $color-white;
  border-radius: 25px;
  width: 50px;
}

input[type='text']:placeholder {
  color: $color-highlight-red;
  opacity: 1;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  /*-ms-overflow-style: -ms-autohiding-scrollbar;*/
}

html,
body {
  line-height: 1;
  min-width: 320px;
  padding: 0;
  margin: 0;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  font-family: $MuseoSansRegular;
  font-size: 14px;
  background: var(--primaryBackground);
  overflow-x: hidden;
  color: var(--primaryTextColor);
  transition: background 0.5s ease, color 0.5s ease, fill 0.5s ease, stroke 0.5s ease;

  //TODO get from font variable
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'IBM Plex Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  //TODO get from font variable
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul#nav {
  margin-bottom: unset !important;
}

a {
  text-decoration: none;
  color: $color-black;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    outline: 0;
    color: $color-primary;
  }
}

textarea {
  color: var(--primaryTextColor);
  background-color: var(--secondaryBackground);
}

@include media('m-large', 'max') {
  html,
  body {
    overflow-x: hidden;
  }
}

@include media('d-large', 'min') {
  .container.main {
    display: flex;
    padding: 0 !important;
    margin: 0;
    max-width: none;
  }
  .content {
    width: 100%;
  }
}

@include media-mix('m-small', 'd-small') {
  .widget-full-insights {
    .insight-widget {
      .view-container-widget {
        border-left: none;
        border-top: solid 1.5px $color-grey;
        .insights-content {
          width: 100%;
        }
      }
    }
  }
}

@include media-mix('t-medium', 'd-small') {
  .widget-full-insights {
    .insight-widget {
      .view-container-widget {
        border-left: none;
        border-top: solid 1.5px $color-grey;

        .insights-content {
          width: 100%;
        }
      }
    }
  }
}

@include media-mix-device('m-small', 'd-small') {
  .widget-full-insights {
    .insight-widget {
      .view-container-widget {
        border-left: none;
        border-top: solid 1.5px $color-grey;
      }
    }
  }
}

.singleChart {
  margin-bottom: 40px;
}

.display-hide {
  display: none;
}
.display-show {
  display: block;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: $color-white;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $color-secondary-dark;
}

.custom-scrollbar-mini {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: $color-white;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $color-secondary-dark !important;
  }
}

.custom-scrollbar-large {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $color-white;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $color-primary-light;
  }
}

.mobile-scroll-smooth {
  -webkit-overflow-scrolling: touch;
}

.full-width {
  width: 100%;
}

.field-error-label {
  margin-top: $rem-8;
  color: $color-highlight-error;
  display: none;
  align-items: center;

  .icon {
    color: $color-highlight-orange;
    font-size: $fontsize-18;
    margin-right: 10px;
  }

  &.show {
    display: flex;
  }
}
